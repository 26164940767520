import "./BouncingDotsIndicator.css";

export const BouncingDotsIndicator = () => {
  return (
    <>
      <div className="douncing-dots">
        <div className="bounce1"></div>
        <div className="bounce2"></div>
        <div className="bounce3"></div>
      </div>
    </>
  );
};
