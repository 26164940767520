import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Model } from "survey-core";

import "./App.css";

import SurveyPage, { ISurvey } from "../components/Survey";
import { BouncingDotsIndicator } from "../components/BouncingDotsIndicator";

export interface IApp {
  anonymous?: boolean;
}

function App({ anonymous }: IApp) {
  const { surveyName, userId } = useParams();
  const [survey, setSurvey] = useState<ISurvey>();
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      const endpoint = anonymous
        ? `/api/survey/${surveyName}`
        : `/api/survey/${surveyName}/${userId}`;
      const response = await fetch(`${endpoint}`, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      });
      if (response.status !== 200) {
        setError(true);
      } else {
        const surveyJson = await response.json();
        setSurvey({
          id: surveyJson.id,
          model: new Model(surveyJson.data),
          userId: userId,
        });
      }
    };
    fetchData().catch(console.error);
  }, [surveyName, userId, anonymous]);

  return error ? (
    <div className="app error">Hakemaasi sisältöä ei löytynyt</div>
  ) : survey ? (
    <SurveyPage id={survey.id} model={survey.model} userId={survey.userId} />
  ) : (
    <div className="app loading">
      <BouncingDotsIndicator />
    </div>
  );
}

export default App;
