import "survey-core/defaultV2.min.css";
import "survey-core/survey.i18n";
import "./Survey.css";

import { Model, surveyLocalization, FunctionFactory } from "survey-core";
import { Survey } from "survey-react-ui";
import { Converter } from "showdown";

import surveyTheme from "./theme.json";
import { useState } from "react";

export interface ISurvey {
  id: number;
  model: Model;
  userId?: string;
}

function filterDiseases(params: any[]): any {
  if (!params[0] || !params[1]) return false;
  const disease = params[0];
  const category = params[1];
  return disease.includes(category);
}

FunctionFactory.Instance.register("filterDiseases", filterDiseases);

function animate(oldClass: string, newClass: string, isGoingForward: boolean) {
  const element = document.getElementById("root");
  if (!!element) {
    const classes = element.classList;
    classes.remove(oldClass, "forward", "backward");
    classes.add(newClass, isGoingForward ? "forward" : "backward");
  }
}
function animateIn(isGoingForward: boolean) {
  animate("hidden", "visible", isGoingForward);
}
function animateOut(isGoingForward: boolean) {
  animate("visible", "hidden", isGoingForward);
}

function SurveyComponent({ id, model, userId }: ISurvey) {
  const [hasErrors, setHasErrors] = useState(false);
  var isPageChanging = false;

  model.onAfterRenderSurvey.add(() => {
    animateIn(true);
  });
  model.onCurrentPageChanging.add((_, options) => {
    if (isPageChanging) return;
    options.allow = false;
    setTimeout(function () {
      isPageChanging = true;
      model.currentPage = options.newCurrentPage;
      isPageChanging = false;
    }, 500);
    animateOut(options.isGoingForward);
  });
  model.onCurrentPageChanged.add((_, options) => {
    animateIn(options.isGoingForward);
  });
  model.onValueChanged.add(function (sender, options) {
    async function updateUnfinished() {
      const data = {
        id,
        result: sender.data,
        userId,
      };
      const endpoint = "/api/survey/unfinished";
      const response = await fetch(`${endpoint}`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      });
      if (response.status !== 201) {
        //fail silently for now
      }
    }
    updateUnfinished();
  });
  model.onComplete.add(function (sender, options) {
    async function update() {
      const data = {
        id,
        result: sender.data,
        userId,
      };
      const endpoint = "/api/survey";
      const response = await fetch(`${endpoint}`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      });
      if (response.status !== 201) {
        options.showSaveError();
        setHasErrors(true);
      } else if (hasErrors) {
        setHasErrors(false);
        options.showDataSavingSuccess();
      }
    }
    update();
  });
  const converter = new Converter();
  model.onTextMarkdown.add(function (_survey, options) {
    // Convert Markdown to HTML
    let str = converter.makeHtml(options.text);
    // Remove root paragraphs <p></p>
    str = str.substring(3);
    str = str.substring(0, str.length - 4);
    // Set HTML markup to render
    options.html = str;
  });

  surveyLocalization.defaultLocale = "fi";
  model.locale = "fi";

  model.focusFirstQuestionAutomatic = true;
  model.applyTheme(surveyTheme);

  return (
    <div className="container">
      <Survey model={model} />
    </div>
  );
}

export default SurveyComponent;
